import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import { css } from "@emotion/core"
import { colors, mq } from "../styles/styles"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import Trial from "../components/trial"
import linkResolver from "../utils/linkResolver"
import LocalizedLink from "../components/localizedLink"
import { isExternalLink, isDocumentLink } from "../utils/utils"
import { ArrowScrollToTop } from "../components/arrowScrollToTop"
import '../styles/servicesPage/serviceTab.scss';

const ServicesPage = ({ data, pageContext: { locale }, location }) => {
  const ServicesPageData = data.prismic.servicespage
  return (
    <>
      <SEO
        title={ServicesPageData.meta_title}
        desc={ServicesPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: ServicesPageData.title,
          subtitle: ServicesPageData.subtitle,
          image: {
            image: ServicesPageData.main_image,
            sharp: ServicesPageData.main_imageSharp,
          },
        }}
      />
      <section className="section is-medium service-tab" css={servicesPageCss}>
        <div className="container">
          <div className="hasRichText">
            {RichText.render(ServicesPageData.richtext, linkResolver)}
          </div>

          {ServicesPageData.services_tabs.map(service => {
            return (
              <div
                key={service.service_tab_id}
                className="columns is-centered is-vcentered py-6"
              >
                {service.service_tab_image && (
                  <div className="column is-6">
                    <figure className="image">
                      <Image
                        sharp={service.service_tab_imageSharp}
                        image={service.service_tab_image}
                      />
                    </figure>
                  </div>
                )}

                <div key={service.service_tab_id} className="column is-6">
                  <div className="content">
                    <h3 className="title has-text-left">
                      {RichText.asText(service.service_tab_title)}
                    </h3>
                    <a href={service.service_tab_id === 'formation' && "/images/monstock-certificat-rnq.pdf"}>
                      {RichText.render(service.service_tab_content, linkResolver)}
                    </a>
                  </div>

                  {service.service_tab_link &&
                    isDocumentLink(service.service_tab_link) && (
                      <LocalizedLink
                        to={service.service_tab_link._meta.uid}
                        className="button is-rounded button__primary has-text-white"
                      >
                        {service.service_tab_button}
                      </LocalizedLink>
                    )}
                  {service.service_tab_additional_image && (
                    <figure className="image additional-image">
                      <Image
                        sharp={service.service_tab_additional_imageSharp}
                        image={service.service_tab_additional_image}
                      />
                    </figure>
                  )}
                  {service.service_tab_link &&
                    isExternalLink(service.service_tab_link) && (
                      <div {...service.service_tab_id === 'formation' && { style: { textAlign: 'center' } }}>
                        <a
                          href={service.service_tab_link.url}
                          target={service.service_tab_link.target}
                          rel="noopener noreferrer" //del nofollow because only mailto
                          className="button is-rounded button__primary has-text-white"
                        >
                          {service.service_tab_button}
                        </a>
                      </div>
                    )}
                </div>
              </div>
            )
          })}
        </div>
      </section>
      <Trial trial={data.prismic.allTrialcomponents.edges[0].node} />
      <ArrowScrollToTop />
    </>
  )
}

export default ServicesPage

ServicesPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const ServicesQuery = graphql`
  query ServicesQuery($uid: String!, $locale: String!) {
    prismic {
      servicespage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        richtext
        main_image
        services_tabs {
          service_tab_id
          service_tab_title
          service_tab_content
          service_tab_button
          service_tab_link {
            _linkType
            ... on PRISMIC__Document {
              _meta {
                uid
              }
            }
            ... on PRISMIC__ExternalLink {
              url
              target
            }
          }
          service_tab_image
          service_tab_additional_image
        }
      }
      ...Trial
    }

    site {
      ...SiteInformation
    }
  }
`

const servicesPageCss = css`
  .columns:nth-of-type(odd) {
    flex-direction: row-reverse;
  }

  @media (min-width: 1024px) {
    .column {
      padding-left: 100px;
      padding-right: 100px;
    }
  }
`
